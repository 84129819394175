.cta-top-curve {
  @apply absolute top-0 left-0 right-0 curve-graphic-size;
}

.cta-bottom-curve {
  @apply absolute bottom-0 left-0 right-0 curve-graphic-size;
}

.matrix-section {
  &:nth-child(odd) {
    .cta-top-curve {
      @apply bg-section-curve-top-revert-grey-4;
    }

    .cta-bottom-curve {
      @apply bg-section-curve-bottom-revert-white;
    }
  }
  &:nth-child(even) {
    .cta-top-curve {
      @apply bg-section-curve-top-revert-white;
    }

    .cta-bottom-curve {
      @apply bg-section-curve-bottom-revert-grey-4;
    }
  }
}

.matrix--cta-fullwidth:last-child {
  & > *:last-child {
    @apply mb-0;
  }
}