.matrix-section-top-curve, 
.matrix-section-bottom-curve {
  @apply curve-graphic-size;
}

.matrix-blocks {
  @apply pb-10 md:pb-10-lg;
}

.matrix-section {
  &:nth-child(odd) {
    .matrix-section-top-curve {
      @apply bg-grey-4 bg-section-curve-top-white;
    }

    .matrix-section-bottom-curve {
      @apply bg-grey-4 bg-section-curve-bottom-revert-white;
    }

    .matrix-blocks {
      @apply bg-white;

      .bg-grey-swap {
        @apply bg-grey-4;
      }
    }
  }

  &:nth-child(even) {
    .matrix-section-top-curve {
      @apply bg-white bg-section-curve-top-grey-4;
    }

    .matrix-section-bottom-curve {
      @apply bg-white bg-section-curve-bottom-revert-grey-4;
    }

    .matrix-blocks {
      @apply bg-grey-4;

      .bg-grey-swap {
        @apply bg-white;
      }
    }
  }
}