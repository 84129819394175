input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  @apply hidden;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  @apply px-[8px] py-[4px] border-2 border-grey w-full rounded-sm;
  @apply mb-4 md:mb-4-lg;

  &:focus {
    @apply outline-none border-black;
    @apply shadow-[0_0_0_4px_theme(colors.highlight),inset_0_0_0_2px_theme(colors.black.DEFAULT)];
  }
}

input[type="file"] {
  @apply mb-4 md:mb-4-lg;
}

input[type=checkbox],
input[type=radio] {
  @apply appearance-none w-[40px] h-[40px] flex-shrink-0 flex-grow-0 border-2 border-grey bg-white;

  &:focus {
    @apply outline-none border-black;
    @apply shadow-[0_0_0_4px_theme(colors.highlight),inset_0_0_0_2px_theme(colors.black.DEFAULT)];
  }
}

input[type=checkbox] {
  @apply rounded-sm;

  &:checked {
    @apply bg-[url('/dist/images/icons/checkbox-checked.svg')] bg-no-repeat bg-center;
  }
}

input[type=radio] {
  @apply rounded-full;

  &:checked {
    @apply bg-[url('/dist/images/icons/radio-checked.svg')] bg-no-repeat bg-center;
  }
}

select {
  @apply appearance-none bg-[url("/dist/images/icons/select-chevron.svg")] bg-right bg-no-repeat pr-[40px];
}

textarea {
  @apply min-h-[124px];
}

label {
  @apply block mb-[8px];
}

// no arrows in number field
.no-spinners {
  -moz-appearance: textfield;
}
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pound-symbol-bg {
  @apply bg-[url('../images/icons/number-input-pound.svg')] bg-no-repeat bg-[left_10px_center];
  @apply md:bg-[length:13px_17px] md:bg-[left_15px_center];
}

// radio label buttons - used in donation widget
.radio-label-buttons {
  input[type="radio"] {
    @apply visually-hidden;
  }

  label {
    @apply border-2 border-purple-bright rounded-sm cursor-pointer;
    @apply hover:border-purple hover:text-purple;
  }

  input[type="radio"]:checked + label {
    @apply bg-purple text-white;
  }

  input[type="radio"]:focus-visible + label {
    @apply text-black outline-none bg-highlight shadow-[0_0_0_2px_theme(colors.black.DEFAULT)];
  }
}

// form buttons
form button:not(.custom-button),
form [type=button]:not(.custom-button),
form [type=submit]:not(.custom-button) {
  @apply py-[13px] px-[28px] md:py-[14px] md:px-[32px];
  @apply active:shadow-[inset_0_6px_0_0_rgba(0,0,0,0.25)] active:bg-purple-md;
  @apply focus-visible:shadow-[0_0_0_4px_theme(colors.black.DEFAULT)];
}

// Formie
.fui-legend {
  @apply heading-md mb-sm;
}

.legend-sm {
  .fui-legend {
    @apply font-body font-normal text-base md:text-base-lg tracking-[0] mb-2xs;
  }
}

.legend-lg {
  .fui-legend {
    @apply heading-lg mb-sm;
  }
}

.label-md {
  .fui-label {
    @apply heading-md mb-sm;
  }
}

.label-lg {
  .fui-label {
    @apply heading-lg mb-sm;
  }
}

.input-w-sm {
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    @apply max-w-[386px];
  }
}

.input-w-xs {
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    @apply max-w-[200px];
  }
}

.fui-page-row:first-child {
  .label-lg .fui-label,
  .fui-legend {
    @apply mt-0;
  }
}

.fui-page-row:first-child {
  .fui-type-heading:first-child {
    .fui-heading {
      @apply mt-0;
    }
  }
}

.fui-row-empty:first-child,
.fui-row-empty:first-child + .fui-row-empty,
.fui-row-empty:first-child + .fui-row-empty + .fui-row-empty,
.fui-row-empty:first-child + .fui-row-empty + .fui-row-empty + .fui-row-empty,
.fui-row-empty:first-child + .fui-row-empty + .fui-row-empty + .fui-row-empty + .fui-row-empty,
.fui-row-empty:first-child + .fui-row-empty + .fui-row-empty + .fui-row-empty + .fui-row-empty + .fui-row-empty {
  & + .fui-page-row {
    .label-lg .fui-label,
    .fui-legend {
      @apply mt-0;
    }
  }

  & + .fui-page-row {
    .fui-type-heading:first-child {
      .fui-heading {
        @apply mt-0;
      }
    }
  }
}

.fui-instructions {
  @apply mb-sm text-black-secondary;

  & > *:last-child {
    @apply mb-0;
  }
}

.fui-checkbox,
.fui-radio {
  @apply flex mb-2xs;

  & > input {
    @apply mr-xs;

    & + label {
      @apply mt-[9px] mb-0 md:mt-[6px];
    }
  }
}

.fui-btn-wrapper {
  @apply mt-5 md:mt-5-lg;
}

.fui-required {
  @apply hidden;
}

.fui-error-message {
  @apply font-semibold -mt-[10px] pl-[28px] md:pl-[32px];
  @apply bg-[url('../images/icons/error.svg')] bg-no-repeat bg-[left_center] bg-[length:20px_20px] md:bg-[length:24px_24px];
}

.fui-alert {
  @apply font-semibold border-4 border-error-red p-[16px] mb-8 md:p-[24px] md:mb-8-lg bg-red-bright-tint-10;

  p {
    @apply last:mb-0;
  }
}

.fui-type-multi-line-text {
  .fui-input-wrapper {
    @apply mb-4 md:mb-4-lg;
  }

  textarea {
    @apply mb-3xs;
  }

  .fui-limit-text {
    @apply text-black-secondary;
  }
}

.fui-type-html {
  @apply flow-root;

  &.text-dark-grey {
    .fui-input-wrapper {
      @apply text-black-secondary;
    }
  }
}

.fui-input-wrapper {
  & > input,
  & > select,
  & > textarea {
    @apply block;
  }
}

.fui-fieldset {
  @apply contents;

  &.fui-layout-vertical {
    .fui-input-wrapper {
      @apply mb-4 md:mb-4-lg;

      // checkbox style for "or" value
      .fui-radio {
        input[value="or"] {
          @apply hidden pointer-events-none;

          & + label {
            @apply mt-0 w-[40px] text-center pointer-events-none;
          }
        }
      }
    }
  }

  & + .fui-error-message {
    @apply font-semibold -mt-[24px]
  }
}

.fui-layout-horizontal .fui-layout-wrap {
  @apply flex;

  .fui-radio {
    @apply mr-lg;
  }
}

.fui-field {
  @apply contents;

  &.fui-error {
    @apply block border-l-4 border-l-error-red p-[16px] mb-4 md:mb-4-lg bg-red-bright-tint-10;

    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select,
    textarea {
      @apply border-error-red;
    }

    &.label-lg .fui-label {
      @apply mt-0;
    }
  }

  &.fui-type-payment {
    @apply block;
  }

  &[data-conditionally-hidden] {
    @apply hidden;
  }

  &.fui-label-hidden > .fui-fieldset  > .fui-legend {
    @apply hidden;
  }

  &.fui-hidden {
    @apply hidden;
  }

  &.fui-field-display-none {
    display: none;
  }
}

// card payment field
.fui-input.StripeElement {
  @apply px-[8px] py-[4px] border-2 border-grey w-full rounded-sm;
  @apply flex flex-col justify-center h-[34px] md:h-[40px];
  @apply mb-4 md:mb-4-lg;

  &:focus {
    @apply outline-none border-black;
    @apply shadow-[0_0_0_4px_theme(colors.highlight),inset_0_0_0_2px_theme(colors.black.DEFAULT)];
  }
}

// multipage
[data-fui-page-hidden] {
  @apply hidden;
}

// progress bar
.fui-progress {
  @apply relative border border-purple-bright rounded-sm h-[16px] mb-3xs;
}

.fui-progress-bar {
  @apply absolute left-0 top-0 h-full bg-purple-bright text-transparent text-[0px];
}

.fui-progress-value {
  @apply hidden;
}

// go back buttons
form button.fui-go-back-button,
form button.fui-go-back-button[type="submit"] {
  @apply bg-transparent p-0 mb-[22px] text-2xs md:text-2xs-lg font-normal rounded-none text-link underline decoration-1 underline-offset-[3px] hover:text-link-hover hover:decoration-[3px] active:bg-transparent active:shadow-none;
  @apply focus-visible:no-underline focus-visible:bg-highlight focus-visible:shadow-[0_-3px_0_0_theme(colors.highlight),0_1px_0_0_theme(colors.highlight),0_4px_0_0_theme(colors.black.DEFAULT)];
}

// donation amount field
[data-field-handle="donationAmount"] .fui-field-container {
  @apply flex items-center py-2xs border-b border-grey my-6 md:my-6-lg;

  label {
    @apply mb-0 font-semibold;
  }

  .fui-input-wrapper {
    @apply pl-[24px] before:content-['£'];
  }

  input {
    @apply mb-0 p-0 border-none bg-transparent inline-block w-auto no-spinners;
  }
}

[data-field-handle="cardDetails"] > .fui-field-container > label {
  @apply hidden;
}

// giftaid
[data-field-handle="giftaid"] > fieldset > legend {
  @apply w-[182px] h-[64px] bg-[url('../images/logos/gift-aid.svg')] bg-no-repeat bg-contain bg-left-top text-transparent;
}

// '(optional)' marker
.fui-field:not(.fui-field-required):not(.no-optional-indicator):not(.fui-type-address-auto-complete):not(.fui-type-html) > * {
  > .fui-legend,
  > .fui-label {
    &:after {
      content: " (optional)";
    }
  }
}

// Sometimes Formie uses a legend element, sometimes a label. It seems inconsistent frankly, but we often want them to display the same
// This utility class ('small-legend') can be added to a Formie field to make the legend element appear like a label (i.e. smaller).
.fui-field.small-legend .fui-fieldset > .fui-legend {
  font-family: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  margin-top: 0;
  @apply block mb-[8px];
}