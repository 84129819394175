// ==========================================================================
// Loading
// ==========================================================================

@keyframes loading {
  0% {
      transform: rotate(0)
  } 100% {
      transform: rotate(360deg)
  }
}

.fui-loading {
  position: relative;
  pointer-events: none;
  color: transparent !important;
  min-height: 1rem;

  &::after {
      position: absolute;
      display: block;
      height: 1rem;
      width: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      border-width: 2px;
      border-style: solid;
      border-radius: 9999px;
      border-color: #ffffff;
      animation: loading 0.5s infinite linear;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      left: 50%;
      top: 50%;
      z-index: 1;
  }
}

.fui-loading.fui-loading-lg {
  min-height: 2rem;

  &::after {
      height: 2rem;
      width: 2rem;
      margin-top: -1rem;
      margin-left: -1rem;
  }
}

.fui-loading.fui-loading-sm {
  min-height: 1rem;

  &::after {
      height: 1rem;
      width: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.375rem;
  }
}

.fui-loading.fui-loading-tiny {
  min-height: 0.75rem;

  &::after {
      height: 0.75rem;
      width: 0.75rem;
      margin-top: -6px;
      margin-left: -6px;
  }
}



.btn.submit.fui-loading {
  color: transparent !important;
}

.btn.submit.fui-loading::after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.fui-loading {
  color: transparent !important;
}