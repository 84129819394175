#filter {
  .tags {
    label {
      @apply bg-white;
    }

    input[type="checkbox"]:checked + label {
      @apply text-white bg-black;
    }
  }
}