@font-face {
  font-family: 'RFC Stencil';
  font-style: normal;
  font-display: auto;
  src: url('/dist/fonts/RFCStencil-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'RFC Heading';
  font-style: normal;
  font-display: auto;
  src: url('/dist/fonts/RFCHeadings-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: auto;
  font-weight: 600;
  src: url('/dist/fonts/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: auto;
  font-weight: 500;
  src: url('/dist/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: url('/dist/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: url('/dist/fonts/Inter-Italic.woff2') format('woff2');
}

